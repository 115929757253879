.refDetails {
  margin: 0 auto 80px;
  max-width: calc(100% - 30px);
}

.refDetailsList {
  border: 1px double #ffffff;
  padding: 0px;
  border-radius: 5px;
  position: relative;
}
.refDetailsList .table-responsive {
  overflow: auto;
}
.refDetailsList table {
  min-width: 700px;
}
.refDetailsList table thead {
  position: sticky;
  top: 0;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  z-index: 99;
  -webkit-position: sticky;
}
.refDetailsList table th {
  background-color: #3c3c3c;
  padding: 15px;
  font-weight: 500;
  font-size: 18px;
}
.refDetailsList table th:nth-child(1) {
  border-radius: 6px 0px 0px 0px;
}
.refDetailsList table th:nth-last-child(1) {
  border-radius: 0px 6px 0px 0px;
}
.refDetailsList table td {
  font-size: 16px;
  padding: 10px 15px;
  border-bottom: 1px solid #3c3c3c;
}
.refDetailsList table tr:nth-last-child(1) td {
  border-bottom: none;
}

.myPagination {
  margin: 0 auto;
  margin-top: 20px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: rgba(255, 255, 255, 0.4509803922);
}
.myPagination button.Mui-selected {
  background-color: rgba(252, 252, 252, 0.8196078431);
}

.claimBonus .buttonGrp {
  margin: 0 auto;
  text-align: center;
}
.claimBonus .buttonGrp button {
  background: #14141b !important;
  color: #ffffff;
  position: relative;
  border: none;
}
.claimBonus .buttonGrp button::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: calc(100% + 8px);
  width: calc(100% + 8px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
.claimBonus .buttonGrp button::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: calc(100% + 0px);
  width: calc(100% + 0px);
  background: #14141b;
  border-radius: 6px;
  z-index: -1;
}

@media (max-width: 575px) {
  .balanceOverviewBox {
    padding: 20px;
  }
}/*# sourceMappingURL=RefDetails.css.map */