.progress {
  --cui-progress-height: 30px;
  --cui-progress-bg: rgba(255, 255, 255, 0.29);
  --cui-progress-bar-bg: #fd5dff ;
}

.buySell {
  margin: 60px auto 60px;
}
.buySell .buySellbox {
  padding: 30px;
  height: 100%;
  background-color: transparent;
  border: 1px solid #a1a1a1;
  border-radius: 10px;
}
.buySell .buySellbox .topttl {
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 8px;
  text-align: right;
}
.buySell .buySellbox .fillBox {
  display: block;
  width: 100%;
  padding: 25px;
  background-color: rgba(255, 255, 255, 0.161);
  border: none;
  border-radius: 5px;
  margin-bottom: 8px;
  font-size: 20px;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}
.buySell .buySellbox .fillBox span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #ffffff;
}
.buySell .buySellbox .buttonGrp {
  text-align: center;
  width: 100%;
}
.buySell .buySellbox .oc_btn {
  min-width: auto;
  display: inline-block;
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: -3.5rem;
  }
}/*# sourceMappingURL=BuySell.css.map */