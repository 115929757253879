footer {
  text-align: center;
  padding: 30px 15px;
}
footer .socialIcons {
  margin-top: 20px;
  margin-bottom: 30px;
}
footer ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
footer ul li {
  margin: 0px 8px;
}
footer .copyright {
  text-align: center;
}
footer .copyright p {
  margin: 0px;
  font-size: 14px;
}

@media only screen and (max-width: 575px) {
  footer ul li {
    margin: 0px 3px;
  }
  footer ul li a {
    width: 35px;
    height: 35px;
  }
  footer ul li svg {
    padding: 7px;
  }
}/*# sourceMappingURL=Footer.css.map */