.bannerSection {
    .bannerinfo {
        width: auto;
        margin-top: 30px;
        background: linear-gradient(189.57deg, rgb(232, 250, 254) -83.55%, rgb(18, 93, 96) -40.3%, rgb(251, 46, 253) 123.53%, rgb(2, 18, 29) 176.26%);
        border-radius: 15px;
        padding: 35px;
        margin-right: 0px;
        min-height: 43vh;

        .bannertitlearea {
            background-color: #0b999bd6;
            width: fit-content;
            padding: 5px;
            border-radius: 12px;

            .bannertitle {
                font-family: 'orbitron';
                letter-spacing: 0.08em;
            }
        }

        .bannerlogo {
            max-width: 40px;
        }

        .bannermainheading {
            margin: 0 0 30px;
            font-size: 48px;
            font-weight: 400;
        }

        .bannerbtn {
            text-align: center;
            background-color: transparent;
            color: #ffffff;
            text-decoration: none;
            padding: 10px 20px;
            border-radius: 8px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            border: 2px solid #ffffff;
        }

        .bannerimage {
            max-width: 500px;

            .rocketimg {
                object-fit: cover;
                height: 100%;
                width: 100%;
                border-radius: 10px;
            }

        }
    }

    .alice-carousel__dots-item:not(.__custom) {
        background-color: #ffffff;
        border-radius: 2px;
        height: 15px;
        transform: skew(-10deg);
        width: 15px;
        opacity: 0.3;
    }

    .alice-carousel__dots-item:not(.__custom):hover,
    .alice-carousel__dots-item:not(.__custom).__active {
        background-color: #ffffff;
        opacity: 1;
    }

    @media (max-width:768px) {
        .bannerinfo {
            margin-top: 0px;
            // padding-inline: 5px;
            margin-right: 0px;

            .bannermainheading {
                font-size: 30px;
            }
        }

        .bannerinfo .bannerimage {
            display: none;
        }
    }
}