@keyframes round {
  100% {
    transform: rotateY(-360deg);
  }
}
.projects {
  margin: 0 0 0px;
}
.projects .listcurrent .imageBox {
  text-align: center;
}
.projects .listcurrent .imageBox img {
  max-width: 450px;
  width: 100%;
  filter: drop-shadow(2px 4px 6px black);
}
.projects .countBox {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 10px;
}
.projects .countBox .countinbox {
  padding: 15px;
  height: 100%;
  position: relative;
}
.projects .countBox svg {
  height: 50px;
  display: block;
  margin-bottom: 15px;
  margin-inline: auto;
}
.projects .countBox svg path {
  fill: #ffffff;
}
.projects .countBox h2 {
  font-size: 32px;
}
.projects .countBox h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #ffffff;
}
.projects .countBox strong {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #ffffff;
}
.projects .countBox p {
  font-size: 14px;
  font-weight: 500;
  margin: 0px 0px 8px;
}
.projects .col-sm-6 {
  padding: 15px;
}
.projects .alice-carousel .listBoxInner {
  border: 1px solid #ff0000;
}
.projects .infoBox {
  position: relative;
}
.projects .infoBox .label {
  position: absolute;
  overflow: hidden;
  width: 120px;
  height: 120px;
  top: -25px;
  right: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.projects .infoBox .label::before {
  content: "ICO";
  position: absolute;
  width: 150%;
  height: 30px;
  background-image: linear-gradient(45deg, #ff6547 0%, #ffb144 51%, #ff7053 100%);
  transform: rotate(44deg) translateY(-20px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
  font-size: x-large;
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
}
.projects .infoBox .labelend::before {
  content: "ICO Ended";
  position: absolute;
  width: 150%;
  height: 30px;
  background-image: linear-gradient(45deg, #ff6547 0%, #ffb144 51%, #ff7053 100%);
  transform: rotate(44deg) translateY(-20px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
  font-size: 12px;
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
}
.projects .infoBox .label1::before {
  content: "Seed Sale Ended";
  color: #000;
  background: #ffb46a;
  font-size: 11px;
}
.projects .infoBox .label::after {
  content: "";
  position: absolute;
  width: 10px;
  bottom: 0;
  left: 0;
  height: 10px;
  z-index: -1;
  box-shadow: 140px -140px #cc3f47;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
}
.projects .infoBox .label.labelend::after {
  display: none;
}
.projects .borderColors1 {
  border-color: #ffb46a !important;
}
.projects .borderColors2 {
  border-color: #ff9984 !important;
}
.projects .borderColors3 {
  border-color: #ff84a7 !important;
}
.projects .borderColors4 {
  border-color: #f09eff !important;
}
.projects .borderColors5 {
  border-color: #97baff !important;
}
.projects .borderColors6 {
  border-color: #7bedff !important;
}
.projects .listBoxInner {
  border-radius: 10px;
  padding: 20px;
  background-color: #18141d;
  transition: all 0.5s;
  position: relative;
  border: 5px solid;
}
.projects .listBoxInner .labelbox {
  display: flex;
  align-items: center;
}
.projects .listBoxInner .salestatus {
  margin: 5px 20px 10px;
  font-size: 13px;
  border-radius: 5px;
  /* display: none; */
  font-size: 12px;
  font-weight: bolder;
  padding: 5px 10px;
  text-align: center;
  background-color: #ffeaef;
  color: #48c774;
}
.projects .listBoxInner .salestatus span {
  border-radius: 50px;
  display: inline-block;
  height: 8px;
  margin-right: 4px;
  width: 8px;
  background: green;
}
.projects .listBoxInner .salestatus.active {
  color: #ff3465;
}
.projects .listBoxInner .salestatus.active span {
  background-color: #ff3465;
}
.projects .listBoxInner .profilePicBox {
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -50px;
  padding: 0px;
}
.projects .listBoxInner .profileInfo {
  align-items: center;
  justify-content: space-between;
  margin: 0px;
}
.projects .listBoxInner .profilePic {
  flex: 0 0 56px;
  max-width: 56px;
  height: 56px;
  border-radius: 50%;
  padding: 0px;
  filter: drop-shadow(2px 4px 6px black);
}
.projects .listBoxInner ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  text-align: right;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.projects .listBoxInner ul li {
  display: none;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}
.projects .listBoxInner ul li span {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  display: inline-block;
  margin-right: 4px;
}
.projects .listBoxInner ul li.upcoming {
  background-color: #8bd1ff;
  color: #000000;
}
.projects .listBoxInner ul li.upcoming span {
  background-color: #000;
}
.projects .listBoxInner ul li.saleLive {
  background-color: #d1fae5;
  color: #10b981;
}
.projects .listBoxInner ul li.saleLive span {
  background-color: #10b981;
}
.projects .listBoxInner ul li.saleEnded {
  background-color: #e9acac;
  color: #ff3465;
}
.projects .listBoxInner ul li.saleEnded span {
  background-color: #ff3465;
}
.projects .listBoxInner ul li.active {
  display: inline-block;
}
.projects .listBoxInner h2 {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  display: block;
}
.projects .listBoxInner .subtitle {
  font-size: 15px;
  font-weight: 600;
  color: #888;
  margin: 0px;
  display: block;
}
.projects .listBoxInner h3 {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  display: block;
}
.projects .listBoxInner strong {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  margin: 5px 0px 10px;
  display: block;
}
.projects .listBoxInner .progressBar {
  margin: 3px 0 0px;
  font-size: 15px;
}
.projects .listBoxInner .progresLine {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  background-color: rgba(108, 108, 108, 0.35);
  border-radius: 100px;
  height: 8px;
  margin: 15px 0px;
}
.projects .listBoxInner .progresLine span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #ffffff;
  border-radius: 3px;
}
.projects .listBoxInner .progresLine span::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: calc(100% + 8px);
  width: calc(100% + 8px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
  z-index: 2;
}
.projects .listBoxInner .tableBox p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  margin: 0 0 5px;
}
.projects .listBoxInner .tableBox p span {
  font-size: 15px;
  color: #888;
  font-weight: 600;
}
.projects .listBoxInner .tableBox p.ttl {
  color: #888;
  font-size: 13px;
  margin: 0 0 15px;
  font-weight: 600;
}
.projects .listBoxInner .tableBox p.ttl span {
  font-size: 13px;
}
.projects .listBoxInner .dividerLine {
  display: block;
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.368627451);
  margin-top: 5px;
}
.projects .listBoxInner .saleTineView {
  align-items: center;
  margin: 0px;
  z-index: 2;
  position: relative;
  justify-content: end;
}
.projects .listBoxInner .saleTineView .timesSale {
  margin: 0px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 105px);
  max-width: calc(100% - 105px);
  margin-right: 15px;
  padding: 0px;
  font-size: 14px;
}
.projects .listBoxInner .saleTineView .timesSale span {
  color: #ffffff;
  display: block;
  font-size: 14px;
  margin-top: 4px;
  letter-spacing: 1.2px;
  font-weight: 600;
}
.projects .listBoxInner .saleTineView .btnView {
  flex: 0 0 90px;
  max-width: 90px;
  text-align: center;
  background-color: transparent;
  color: #000000;
  text-decoration: none;
  padding: 10px 5px;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: relative;
}
.projects .listBoxInner .saleTineView .btnView::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
.projects .listBoxInner .saleTineView .react-countdown {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.projects .listBoxInner .saleTineView .react-countdown li {
  display: inline-block;
  margin-right: 4px;
}
.projects .listBoxInner .saleTineView .react-countdown li::after {
  top: 5px;
  font-size: 14px;
  right: -7px;
}
.projects .listBoxInner .saleTineView .react-countdown .digit {
  color: #ffffff;
  margin: 5px 0px 10px;
  display: block;
  font-size: 14px;
  font-weight: bold;
}
.projects .listBoxInner .saleTineView .react-countdown .text {
  display: none;
}
.projects .listBoxInner:hover .profilePic {
  animation: round 2s linear 1;
}
.projects .listBoxInner:hover::before {
  opacity: 1;
  mix-blend-mode: unset;
}
.projects .listBoxInner:hover::after {
  opacity: 1;
  mix-blend-mode: unset;
}
.projects .listBoxInner.active {
  animation: blinkOcPublicBrn 1.5s infinite;
  transition: all 0.2s ease-in-out;
  box-shadow: none;
  text-shadow: none;
}
@keyframes blinkOcPublicBrn {
  0% {
    border-color: #ffffff;
    opacity: 1;
    box-shadow: none;
    text-shadow: none;
  }
  49% {
    border-color: #ffffff;
    color: #ffffff;
    text-shadow: -0.2rem -0.2rem 1rem #ffffff, 0.2rem 0.2rem 1rem #ffffff, 0 0 2rem #ffffff, 0 0 4rem #ffffff, 0 0 6rem #ffffff, 0 0 8rem #ffffff, 0 0 10rem #ffffff;
    box-shadow: 0 0 0.1rem #ffffff, inset 0 0 0.1rem #ffffff, 0 0 2rem #ffffff, inset 0 0 0rem #ffffff, 0 0 1rem #ffffff, inset 0 0 1rem #ffffff;
  }
  50% {
    border-color: #ffffff;
    opacity: 1;
    box-shadow: none;
    text-shadow: none;
  }
}
.projects .listBoxInner.active .infoBox {
  text-shadow: none;
}
.projects .listBoxInner.liveline {
  position: relative;
  border-color: transparent !important;
}
.projects .listBoxInner.liveline::after {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border-radius: 10px;
  border: 5px solid transparent;
  animation-name: blinking;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes blinking {
  50% {
    border-color: #93fff1;
  }
}

.alice-carousel__dots-item:not(.__custom) {
  background-color: #ffffff;
  border-radius: 2px;
  height: 15px;
  transform: skew(-10deg);
  width: 15px;
  opacity: 0.3;
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #ffffff;
  opacity: 1;
}

@media only screen and (max-width: 575px) {
  .projects .countBox .countinbox {
    margin-bottom: 20px;
    height: auto;
  }
}
.hideui {
  opacity: 0.5;
}/*# sourceMappingURL=Projects.css.map */