.publicSale {
    margin: 0px 0px 60px;
    overflow: hidden;

    .infobox {
        margin-bottom: 15px;
        position: relative;
        z-index: 9;

        h1 {
            margin: 0 0 30px;
            font-size: 78px;
            line-height: 1;
            font-weight: bolder;
            color: #3c2900;
            text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #ffe656, 0 0 2px #ffe656, 0 0 0 #ffe656, 0 0 2px #ffe656, 0 0 11px #ffe656;
        }

        p {
            font-size: 18px;
            max-width: 480px;
        }

        ul {
            padding: 0px;
            margin: 0px 0px 30px;
            list-style: none;

            li {
                margin-bottom: 15px;
            }
        }

        h3 {
            font-size: 20px;
            margin: 0 0 10px;
        }

        .copybox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: #ffffff;
            max-width: max-content;
            padding: 12px 15px;
            border-radius: 5px;
            color: #ffffff;

            p {
                margin: 0px;
            }

            svg {
                font-size: 24px;
                margin-left: 20px;
                cursor: pointer;
                fill: #fff43c;
            }
        }
    }

    .imageBox {
        text-align: center;

        img {
            max-width: 500px;
            width: 100%;
            filter: drop-shadow(2px 4px 6px black);
        }
    }
}

@media only screen and (max-width: 991px) {
    .publicSale .infobox h1 {
        font-size: 48px;
    }

    .buttonGrp.topbaner {
        margin: 30px 0px 0px;

        .copyRefferalCode {
            margin: 0px 12px 0px 0px;
        }

        .activityDtl {
            margin: 0px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .publicSale {
        text-align: center;
        margin-top: 30px;
    }

    .publicSale .infobox .copybox {
        margin: 20px auto 0px;
    }

    .publicSale .infobox p {
        margin: 0 auto;
    }

    .buttonGrp.topbaner {
        justify-content: center;
        flex-wrap: wrap;
    }

    .buttonGrp.topbaner .copyRefferalCode {
        margin: 0px 8px 10px;
    }

    .buttonGrp.topbaner .activityDtl {
        margin: 0px 8px 0px;
    }
}