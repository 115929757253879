@media only screen and (max-width: 767px) {
  .buttonGrp {
    text-align: center;
    margin-top: 15px;
  }

  .oc_container {
    max-width: calc(100% - 30px);
  }

  .oc_btn.copyRefferalCode {
    margin-left: auto;
    margin-right: auto;
  }

  .publicSale .infobox h1 {
    font-size: 42px;
  }

  .btnsalebnr {
    margin: 30px auto;
  }
}

@media (max-width: 450px) {
  .buySell .buySellbox .oc_btn {
    display: block;
    width: 100%;
  }

  .buyOc .buyOcbox .oc_btn {
    display: block;
    width: -webkit-fill-available;
  }

  .buySell .buySellbox,
  .buyOc .buyOcbox {
    padding: 20px;
  }
}