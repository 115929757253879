.saleDetails {
    margin: 0 auto 110px;
    max-width: calc(100% - 8px);
}

.saleDetailsList {
    padding: 20px;
    border-radius: 6px;
    position: relative;
    background-color: #17141e;

    &::before {
        content: "";
        position: absolute;
        top: -4px;
        left: -4px;
        height: calc(100% + 8px);
        width: calc(100% + 8px);
        background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
        border-radius: 12px;
        z-index: -1;
        animation: animatedgradient 3s ease alternate infinite;
        background-size: 300% 300%;
    }

    .table-responsive {
        overflow: auto;
        max-height: 350px;
    }

    table {
        min-width: 700px;

        th {
            background-color: #68326b;
            padding: 15px;
            font-weight: 500;
            font-size: 18px;

            &:nth-child(1) {
                border-radius: 6px 0px 0px 0px;
            }

            &:nth-last-child(1) {
                border-radius: 0px 6px 0px 0px;
            }
        }

        td {
            font-size: 16px;
            padding: 15px 15px;
        }

        tr {
            &:nth-last-child(1) {
                td {
                    border-bottom: none;
                }
            }
        }
    }
}

@media (max-width: 575px) {
    .balanceOverviewBox {
        padding: 20px;
    }
}

@media (max-width: 767px) {
    .saleDetailsList {
        text-align: center;
    }
}

@media (max-width: 991px) {
    .saleDetails {
        margin: 0 auto 30px;
    }

    .projects .countBox {
        background-color: transparent;
        border-radius: 0;
        border: none;
    }
}