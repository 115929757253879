// Footer Css Start
footer {
    text-align: center;
    padding: 30px 15px;

    .socialIcons {
        margin-top: 20px;
        margin-bottom: 30px;
    }

    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
            margin: 0px 8px;

          

           
        }
    }

    .copyright {
        text-align: center;

        p {
            margin: 0px;
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 575px) {
    footer ul li {
        margin: 0px 3px;
    }

    footer ul li a {
        width: 35px;
        height: 35px;
    }

    footer ul li svg {
        padding: 7px;
    }
}

// Footer Css End