.accordion {
  --cui-accordion-bg: #02121d;
  --cui-accordion-active-bg: none;
  --cui-accordion-active-color: none;
  --cui-accordion-btn-padding-x: 10px;
  --cui-accordion-body-padding-x: 1.25rem;
  --cui-border-color: transparent;
}

.statisics {
  margin: 0 0 60px;
}
.statisics .boxinner {
  height: 100%;
}
.statisics .boxinner .accordion-flush .accordion-item {
  border: 1px solid #a1a1a1;
  padding: 40px 30px;
  height: 100%;
  border-radius: 10px;
  position: relative;
  background-color: #17151c;
}
.statisics .boxinner .accordion-flush .accordion-item .accordion-button {
  position: relative;
  z-index: 1;
}
.statisics .boxinner .accordion-button:not(.collapsed) {
  box-shadow: none;
}
.statisics .boxinner .accordion-button:focus {
  box-shadow: none;
}
.statisics .boxinner .accordion-button::after {
  display: none;
}
.statisics .boxinner .accordion-button {
  background-color: transparent;
}
.statisics .boxinner .accordion-button .arrow {
  transform: rotate(90deg);
}
.statisics .boxinner .accordion-button.collapsed .arrow {
  transform: rotate(0deg);
}
.statisics .boxinner .stat-info {
  padding: 10px;
}
.statisics .boxinner .stat-info .inputlabel {
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  background-color: #17151c;
  position: relative;
}
.statisics .boxinner .stat-info .inputlabel::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
.statisics .boxinner .stat-info .inputlabel p {
  color: #ffffff;
  font-size: 24px;
}
.statisics .boxinner .stat-info .inputlabel strong {
  color: #ffffff;
  font-size: 22px;
  display: block;
}
.statisics .boxinner .viewbtn {
  text-align: center;
  background-color: transparent;
  color: #000000;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 14px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  position: relative;
}
.statisics .boxinner .viewbtn::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: calc(100% + 8px);
  width: calc(100% + 8px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
.statisics .boxinner h2 {
  font-size: 28px;
  padding: 0;
  margin: 0px 0px 0px;
  display: block;
  color: #ffffff;
  padding: 5px;
  position: absolute;
  top: -60px;
  background-color: #17151c;
}
.statisics .boxinner .box {
  text-align: center;
  margin: 0 0 30px;
  border-radius: 5px;
  padding: 30px 10px;
  border: 1px solid #ffffff;
  box-shadow: 3px 3px 0px #ffffff;
}
.statisics .boxinner .box h3 {
  font-size: 18px;
  min-height: 44px;
  color: #ffffff;
  margin: 0 0 15px;
}
.statisics .boxinner .box h5 {
  font-size: 18px;
  color: #ffffff;
  margin: 0 0 15px;
}
.statisics .boxinner .box p {
  margin: 0 0 15px;
  color: #fff;
}
.statisics .boxinner .box strong {
  margin: 0 0 15px;
  display: block;
  color: #ffffff;
}
.statisics .boxinner.rightinfo .box {
  border: 1px solid #ababab;
  box-shadow: 3px 3px 0px #575757;
  background-color: #161616;
}
.statisics .boxinner.rightinfo .box strong {
  color: #ffffff;
}
.statisics .boxinner.leftinfo {
  margin: 0 0 60px;
}
.statisics .copyReferralLink {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .statisics {
    margin: 0 0 40px;
  }
  .statisics .stat-info .viewbtn {
    max-width: 100%;
  }
  .statisics .boxinner h2 {
    text-align: center;
    color: #ffffff;
  }
  .statisics .boxinner.leftinfo {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 567px) {
  .statisics .boxinner .accordion-flush .accordion-item {
    padding: 30px 5px 0px;
  }
  .statisics .boxinner .accordion-button::after {
    background-size: 0.875rem;
  }
  .statisics .boxinner h2 {
    font-size: 22px;
  }
  .statisics .boxinner h2 {
    top: -50px;
  }
}
@media only screen and (max-width: 420px) {
  .statisics {
    margin: 0 0 30px;
  }
  .statisics .boxinner .accordion-flush .accordion-item {
    padding: 30px 5px 0px;
  }
  .statisics .boxinner h2 {
    font-size: 20px;
  }
  .statisics .boxinner.leftinfo {
    margin-bottom: 30px;
  }
}/*# sourceMappingURL=Statisics.css.map */