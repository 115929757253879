.navbar {
  height: 60px;
  background-color: #fef7e5;
  position: relative;
}

.menu-icon {
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  width: 30px;
  transform: translateY(-50%);
}
.menu-icon img {
  filter: invert(1);
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0px;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 30px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
}

.nav-elements ul a:hover {
  color: #ffffff;
}

.nav-elements ul a:hover::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
}

nav.navbar {
  position: relative;
  width: 1320px;
  max-width: calc(100% - 10vw);
  z-index: 3;
  height: 115px;
  background-color: transparent;
  justify-content: space-between;
  margin: 0 auto;
}
nav.navbar .logo {
  flex: 0 0 10vw;
  max-width: 140px;
  min-width: 80px;
}
nav.navbar .navbar_inner {
  width: 100%;
  position: relative;
}
nav.navbar .balancebox ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  list-style: none;
}
nav.navbar .balancebox ul li {
  margin-left: 15px;
  font-weight: 400;
}
nav.navbar .balancebox ul li strong {
  font-weight: bold;
  margin-right: 10px;
}
nav.navbar .balancebox ul li span {
  color: #fff;
  display: block;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  background-color: #000000;
  font-size: 20px;
}
nav.navbar .balancebox ul li span::after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
nav.navbar .balancebox ul li span.logoutSn {
  background-color: transparent;
  color: #000000;
  font-weight: 400;
}
nav.navbar .balancebox ul li span.logoutSn svg {
  margin-left: 8px;
  font-size: 16px;
  opacity: 0.8;
  margin-top: -2px;
}
@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media (max-width: 1180px) {
  .nav-elements ul {
    padding: 0;
  }
  .nav-elements ul li:not(:last-child) {
    margin-right: 15px;
  }
  .nav-elements ul a {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .balancebox {
    margin-right: 0px;
    margin-left: auto;
  }
  .menu-icon {
    display: block;
    cursor: pointer;
  }
  .nav-elements {
    position: absolute;
    right: 0px;
    top: 100%;
    width: 100%;
    height: auto;
    transition: all 0.3s ease-in;
    overflow: hidden;
    max-width: 320px;
    transform: scale(0);
    transform-origin: top right;
  }
  .nav-elements.active {
    transform: scale(1);
  }
  .nav-elements ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    background-color: #ffffff;
    border-radius: 5px;
  }
  .nav-elements ul li {
    margin-right: 0px;
  }
  .nav-elements ul li:not(:last-child) {
    margin-right: 0px;
  }
  .nav-elements ul li a {
    padding: 15px;
    display: block;
    color: #000000;
    font-weight: bold;
  }
  .nav-elements ul li a:hover {
    color: #000000;
  }
}
@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
  nav.navbar .balancebox ul li {
    margin-left: 15px;
    font-size: 14px;
  }
}
@media (max-width: 520px) {
  nav.navbar .balancebox ul li span {
    padding: 6px 8px;
    font-size: 15px;
  }
}
@media (max-width: 390px) {
  .menu-icon {
    right: 10px;
    width: 20px;
  }
  nav.navbar {
    top: 0px;
  }
  .publicSale .infobox h1 {
    font-size: 38px;
  }
  nav.navbar .logo {
    max-width: 100px;
    min-width: 75px;
  }
  nav.navbar .balancebox ul li span.logoutSn {
    font-size: 0px;
    padding: 0px;
  }
  nav.navbar .balancebox ul li span.logoutSn::after {
    display: none;
  }
  nav.navbar .balancebox ul li span.logoutSn svg {
    margin-left: 2px;
    font-size: 36px;
    opacity: 1;
    margin-top: 0px;
    fill: #ffffff;
    border: 1px solid #ffffff;
    padding: 7px 7px 7px 9px;
    border-radius: 50px;
  }
}/*# sourceMappingURL=header.css.map */